
  import { mapState, mapActions } from 'vuex'

  export default {
    data() {
      return {
        option: null,
        double: false,
      }
    },
    props: {
      wholeOption: {
        type: Object,
        default: () => {}
      },
      hideDouble: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      this.option = this.wholeOption
    },
    computed: {
      ...mapState({
        choices: state => state.product.choices,
        product: state => state.product?.data,
      }),
      leftOption() {
        return this.getOptionBySide(this.option.name,'Left')
      },
      rightOption() {
        return this.getOptionBySide(this.option.name,'Right')
      },
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      },
      side() {
        if (this.choices.find(choice => choice.choice_id === this.leftOption.choice_id)) {
          return 'Left'
        }
        if (this.choices.find(choice => choice.choice_id === this.rightOption.choice_id)) {
          return 'Right'
        }
        if (this.choices.find(choice => choice.choice_id === this.wholeOption.choice_id)) {
          return 'Whole'
        }
        return false // no side selected
      },
      isActive() {
        let active = false
        this.choices.forEach(choice => {
          if (choice.choice_id == this.leftOption.choice_id || choice.choice_id == this.option.choice_id || choice.choice_id == this.rightOption.choice_id) {
            active = true
          }
        })
        return active
      },
      quickAdd() {
        return !this.$route.path.includes('product')
      }
    },
    methods: {
      ...mapActions({
        selectMod: 'product/selectMod',
        removeMod: 'product/removeMod',
      }),
      calories(option) {
        let base = option.nutrition?.calories?.base
        let max = option.nutrition?.calories?.max
        // if (base) {
        //   base = base.toString().replace('-','')
        // }
        // if (max) {
        //   max = max.toString().replace('-','')
        // }
        return (option.cost && base !== null  ? ' | ' : '') + (base !== null ? base : '') + (max !== null ? ' - '+max : '') + (base !== null ? ' Cal' : '')
      },
      getOptionBySide(name, side) {
        let match = false
        const size = this.choices[0]?.name
        // let size = null
        // if (this.choices && this.choices[0]) {
        //   if (this.choices[0].name.includes('Small')) size = 'Small'
        //   if (this.choices[0].name.includes('Medium')) size = 'Medium'
        //   if (this.choices[0].name.includes('Large')) size = 'Large'
        // }
        // Loop through all mods and check for a mod under the side with the same name
        this.product.mods.forEach(mod => {
          mod.options.forEach(option => {
            // TODO: Convert this condition to a .find() method
            if (size && option.name.toLowerCase().includes(size.toLowerCase())) {
              option.mods.forEach(submod => {
                if (side == submod.name) {
                  submod.options.forEach(suboption => { // Customize L/R/W
                    suboption.mods.forEach(subsubmods => {
                      subsubmods.options.forEach(subsuboption => {
                        if (subsuboption.name == name) {
                          match = subsuboption
                          return
                        }
                        // Didn't match at top level, continue
                        if (!match) {
                          subsuboption.mods.forEach(subsubsubmods => {
                            subsubsubmods.options.forEach(subsubsuboption => {
                              if (subsubsuboption.name == name) {
                                match = subsubsuboption
                              }
                            })
                          })
                        }
                      })
                    })
                  })
                }
              })
            }
          })
        })
        return match
      },
      choice(id) {
        return this.choices.find(choice => choice.id === id)
      },
      isDouble(id) {
        let quantity = 0
        this.choices.forEach(choice => {
          if (choice.id == id) {
            quantity += choice.quantity
          }
        })
        return (quantity > 1)
      },
      async handleSelect(side, qty = 1) {
        if (this.double) {
          qty = 2
        }
        if (side == 'Left') {
          this.option = this.leftOption
        } else {
          this.removeMod({ ...this.leftOption, id: this.leftOption.choice_id })
        }
        if (side == 'Right') {
          this.option = this.rightOption
        } else {
          this.removeMod({ ...this.rightOption, id: this.rightOption.choice_id })
        }
        if (side == 'Whole') {
          this.option = this.wholeOption
        } else {
          this.removeMod({ ...this.wholeOption, id: this.wholeOption.choice_id })
        }
        if (this.choice(this.option.choice_id)) {
          this.removeMod({ ...this.option, id: this.option.choice_id })
        } else {
          this.selectMod({ ...this.option, id: this.option.choice_id, quantity: qty })
          // this.selectMod({ ...this.option, id: this.option.choice_id })
          // if (this.qty > 1) {
          //   setTimeout(() => {
          //     this.selectMod({ ...this.option, id: this.option.choice_id })
          //   },250)
          // }
        }
      },
      selectDouble() {
        this.double = true
        const choice = this.choice(this.option.choice_id)
        if (choice) {
          this.removeMod({ ...this.option, id: this.option.choice_id })
        }
        this.handleSelect(this.side)
      },
      selectSingle() {
        this.double = false
        const choice = this.choice(this.option.choice_id)
        if (choice) {
          this.removeMod({ ...this.option, id: this.option.choice_id })
        }
        this.handleSelect(this.side)
      }
    }
  }
