
  import { mapState, mapActions } from 'vuex'

  export default {
    props: {
      options: {
        type: Array,
        default: () => []
      },
      mod: {
        type: Object,
        default: () => {}
      },
      hideDouble: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState({
        choices: state => state.product.choices,
        product: state => state.product?.data
      }),
      showImages() {
        if (this.options && this.options.length > 0) {
          return !!this.options.find(option => {
            if ('modifier_image' in option.meta && option.meta['modifier_image']) {
              return true
            }
            return option.mods && option.mods.length > 0 
              ? option.mods.find(mod => 'modifier_image' in mod.meta && mod.meta['modifier_image'])
              : false
          })
        }

        return false
      },
    },
    methods: {
      ...mapActions({
        selectMod: 'product/selectMod',
        removeMod: 'product/removeMod',
      })
    }
  }
